import Link from 'next/link'
const Page404 = () => (
    <div className="container vh-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-12">
                <div className="text-center">
                    <h1 className="font-weight-bold size-404 mb-0">404</h1>
                    <h2 className="font-weight-500">Page not found</h2>
                    <p className="text-center mb-5">
                        The page you were looking for does not exist. You might
                        have been following an old <br />
                        link or misspelled something in the URL.s
                    </p>

                    <Link
                        href="/"
                        className="btn btn-primary-theme font-weight-500 btn-large">
                        Back to Home
                    </Link>
                </div>
            </div>
        </div>
    </div>
)
export default Page404
